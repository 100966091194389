import React from "react"
import StandardPage from "../components/standardPage"
import ContactPage from "../components/contact/ContactPage"
import Seo from "../components/seo"
import { PageProvider } from "../context/pageProvider"
import { useLocation } from "@reach/router"
import { Helmet } from "react-helmet"

const SubPage = function ({ pageContext, location }) {
  if (pageContext.page.pageType === "contact") {
    return (
      <PageProvider data={pageContext} location={location}>
        <ContactPage />
      </PageProvider>
    )
  }
  return (
    <PageProvider data={pageContext} location={location}>
      <StandardPage />
    </PageProvider>
  )
}

export default SubPage

export const Head = ({ pageContext }) => {
  const location = useLocation()
  const lang = pageContext.page.seo?.lang || "sv"

  return (
    <PageProvider data={pageContext} location={location}>
      <Helmet>
        <html lang={lang} />
      </Helmet>
      <Seo seo={pageContext.page.seo} />
    </PageProvider>
  )
}
